import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// ProgressBar
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { RouterResolver } from './router.resolver';
// Guard
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: './components/home/home.module#HomeModule',
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		loadChildren: './components/auth/auth.module#AuthModule'
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { useHash: false }),
		NgProgressModule,
		NgProgressRouterModule
	],
	providers: [RouterResolver],
	exports: [RouterModule, NgProgressModule, NgProgressRouterModule]
})

export class AppRoutingModule {}

export const routingComponents = []
