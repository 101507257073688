
import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()

export class RouterResolver implements Resolve<any> {
	  
	constructor(private router: Router) {}

  	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    	return timer(500);
  	}
}