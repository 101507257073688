import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Router
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Notifier
import { NotifierService } from "angular-notifier";
// Environment
import { environment } from "../../environments/environment";
// import Constants from "../services/constants";

@Injectable({
	providedIn: "root"
})
export class GlobalService {
	private readonly notifier: NotifierService;

	constructor(
		private translate: TranslateService,
		private http: HttpClient,
		private _router: Router,
		public notifierService: NotifierService
	) {
		this.notifier = notifierService;
	}

	token(token) {
		localStorage.setItem("token", JSON.stringify(token));
	}

	refreshToken() {
		return this.http.post(`${environment.baseUrl}/refresh`, {});
	}

	me() {
		return this.http.post(`${environment.baseUrl}/me`, {});
	}

	logout() {
		return this.http.post(`${environment.baseUrl}/logout`, {});
	}

	showNotify(status: string, message: string) {
		this.translate.get(message).subscribe((res: string) => {
			this.notifier.notify(status, res);
		});
	}

	getStateAndCity(res: any) {
		const stateKey = "locality",
			cityKey = "administrative_area_level_1",
			response = res[0];

		let state = "",
			city = "";

		response.address_components.forEach(component => {
			component.types.forEach(type => {
				if (type === stateKey) {
					city = component.long_name;
				} else if (type === cityKey) {
					state = component.long_name;
				}
			});
		});

		return { state: state, city: city };
	}

	getTypeServices(account_id) {
		return this.http.get(
			`${environment.baseUrl}/${account_id}/type-services`
		);
	}

	getTypeProducts(account_id) {
		return this.http.get(
			`${environment.baseUrl}/${account_id}/type-products`
		);
	}

	getActivities() {
		return this.http.get(`${environment.baseUrl}/activities`);
	}
}
