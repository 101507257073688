import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// SignaturePad
import { Angular2SignaturepadModule } from "angular2-signaturepad";
// Interceptors && Http
import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
	HttpClient,
} from "@angular/common/http";
import { TokenInterceptor } from "./interceptor/token.interceptor";
// Translator
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// Bootstrap
import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateParserFormatterEN } from "./filters/ng-bootstrap.date-parser-formatter";
// Router
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// Notifier
import { NotifierModule } from "angular-notifier";

@NgModule({
	declarations: [AppComponent],
	imports: [
		NgbModule,
		BrowserModule,
		BrowserAnimationsModule,
		Angular2SignaturepadModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		NotifierModule.withConfig({
			position: {
				horizontal: {
					position: "right",
					distance: 12,
				},
				vertical: {
					position: "top",
				},
			},
			behaviour: {
				autoHide: 3000,
			},
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{
			provide: NgbDateParserFormatter,
			useClass: NgbDateParserFormatterEN,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
