import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  	selector: 'acme-root',
	template: `
		<notifier-container></notifier-container>
		<router-outlet></router-outlet>
	`,
  	styles: []
})

export class AppComponent implements OnInit {

	constructor (private translate: TranslateService) {}
	 
	ngOnInit () {
		this.setDefaultLanguage();
	}

	setDefaultLanguage () {
		const language = localStorage.getItem('language');

		if (!language) {
			localStorage.setItem('language', 'en');

			this.translate.setDefaultLang('en');
			this.translate.use('en');

			return
		}

		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
