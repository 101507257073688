import { Injectable } from '@angular/core';
// Router
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Operators
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Services
import { GlobalService } from 'src/app/services/globals.service'

@Injectable({ 
    providedIn: 'root' 
})

export class AuthGuard implements CanActivate {
    
    constructor(private router: Router, private globalService: GlobalService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
        
        const token = localStorage.getItem('token');

        if ( (token) ) {

            return this.globalService.me().pipe(
                map(user => { 

                    if ( (Object.keys(user).length === 0) ) {

                        this.router.navigate(['/login']);

                        return false
                    }

                    return true
                })
            );
        }

        this.router.navigate(['/login']);

        return of(false);
    }
}
