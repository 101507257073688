import { Injectable } from '@angular/core';
// Router
import { Router }  from '@angular/router';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Http
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// Rxjs
import { Observable, throwError } from 'rxjs';
// Operators
import { catchError, switchMap } from 'rxjs/operators';
// Services
import { GlobalService } from '../services/globals.service';

@Injectable()

export class TokenInterceptor implements HttpInterceptor { 

	public tokenRefresh: boolean = true;
	
	constructor(private translate: TranslateService, private globalService: GlobalService,  private _router: Router) {}

	private redirectToLogin () {

		localStorage.removeItem('token');
		
		return this._router.navigate(['login'])
	}
	
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const token: string = JSON.parse(localStorage.getItem('token'));
		const language = localStorage.getItem('language');

		if (token) {
			if (request.url.indexOf('https://restcountries.eu') === -1) {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
					headers: request.headers.set('X-localization', language)
				});
			}
		}
		
		return next.handle(request).pipe(catchError(error => {
			
			if (error.status === 500 && error.error.message === 'The token has been blacklisted') {

				this.globalService.showNotify('default', this.translate.instant('messages.auth.token.blacklisted'))

				this.redirectToLogin();

			} else if (error.status === 500 && error.error.message === 'Token has expired and can no longer be refreshed') {

				this.globalService.showNotify('default', this.translate.instant('messages.auth.token.refreshed'))

				this.redirectToLogin();

			} else if (error.status === 401 && error.error.message === 'Token has expired') {
				
				return this.globalService.refreshToken().pipe(
					switchMap(res => {
						
						this.globalService.token(res['access_token'])
						
						request = request.clone({
							setHeaders: {
								Authorization: `Bearer ${res['access_token']}`
							}
						});
						
						return next.handle(request);
					})
				);

			} else if (error.status === 422 && error.error.message === 'The given data was invalid.') {

				this.globalService.showNotify('default', this.translate.instant('messages.auth.token.invalid'))

			} else if (error.status === 500 ) {

				//this.globalService.showNotify('default', this.translate.instant('messages.auth.token.500'))
				
				return next.handle(request);

			} else if (error.status === 404 ) {

				this.globalService.showNotify('default', this.translate.instant('messages.auth.token.404'))

			} else if (error.status === 422) {
				
			} else {

				this.redirectToLogin();
			}

            return throwError(error)
		}));
	}
}